import {EnvironmentType} from '@core/types';

const gatewayApiUrl = 'https://sbx-api-gateway.globalpoll.com.ua';

export const environment = {
  production: false,
  euSignCpProxyServerUrl: 'https://proxykey.isolutions.io/ProxyHandler.php',
  backEndApiUrlWss: 'wss://sbx-api-gateway.globalpoll.com.ua/auth-service/auth/diia',
  backEndApiUrl: `${gatewayApiUrl}/auth-service`,
  clientId: 'client',
  cabinetClientId: 'c4b15fa67e014a82ab87a6625dbe075f_poll',
  clientClassifier: '123456',
  crmApiUrl: `${gatewayApiUrl}/crm-core`,
  recaptchaSiteKey: '6Le2u88ZAAAAAC7pAb_Cb5T7xpLkJzCS_M2xFHyq',
  govSignUrl: `${gatewayApiUrl}/auth-service/signin/gov`,
  govDiiaUrl: `${gatewayApiUrl}/auth-service/login/diia/initialize`,
  type: EnvironmentType.SANDBOX,
};
